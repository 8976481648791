/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { getIconWithPopover } from 'helpers/Utils';
/**
 * Renders the verification results for each assessment item, presenting the initial and, if applicable,
 * final verification statuses. This component visualizes the status of each item before and after
 * processing, using icons to indicate success or failure.
 *
 * The verification results are shown for a specified set of assessment items. Each item's initial and
 * final results are determined based on provided data, with icons and tooltips for detailed understanding.
 * The component supports dynamic rendering based on the assessment group and the availability of final results.
 *
 * @param {object} items An object containing metadata for each assessment item, including labels, icons,
 *                       and tooltips. This structure enables the component to dynamically generate content
 *                       based on assessment specifics.
 * @param {object} row The data object representing the assessment results. Contains 'before' and 'after'
 *                     properties to indicate the state of each item pre- and post-assessment.
 *
 * @returns render.
 */
export const IdsResultDetailsAssessments = ({ items, row }) => {
  const { before, after } = row;
  const showFinal = Object.keys(row?.after?.results || {}).length > 0;

  const getValue = (table, key, source) => {
    return items[table][key]?.get(source?.results || {});
  };

  const hasResultsToDisplay = Object.keys(items).some((table) => {
    return Object.keys(items[table]).some((key) => {
      return getValue(table, key, before) || getValue(table, key, after);
    });
  });

  const getIcon = (value) => {
    return value ? ['fas', 'check-circle'] : ['fas', 'times-circle'];
  };
  return (
    <>
      <div className="ids-result-details-header">Assessment</div>
      <div className="ids-result-details">
        {hasResultsToDisplay ? (
          Object.keys(items).map((table) => {
            return (
              <table
                className="ids-result-details-table"
                key={`assessment-${table}`}
                cellSpacing="0"
                cellPadding="0"
              >
                <thead>
                  <tr>
                    <th scope="col" />
                    <th scope="col">Initial</th>
                    {showFinal && (
                      <>
                        <th> </th>
                        <th scope="col">Final</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(items[table]).map((key) => {
                    return (
                      <tr key={`assessment-${key}`}>
                        <td>
                          <div className="ids-result-label">
                            <span className="ids-result-label-text">
                              {items[table][key]?.label}
                            </span>
                            <span className="ids-result-label-icon">
                              {getIconWithPopover({
                                placement: 'top',
                                iconProp: ['far', 'question-circle'],
                                content: items[table][key]?.tooltip,
                                title: items[table][key]?.label,
                              })}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div
                            className={classNames(
                              'ids-result-value-initial',
                              getValue(table, key, before) ? 'success' : 'failed'
                            )}
                          >
                            <FontAwesomeIcon icon={getIcon(getValue(table, key, before))} />
                          </div>
                        </td>
                        {showFinal && (
                          <>
                            <td>
                              <div className="ids-result-value-separator">
                                <FontAwesomeIcon icon={['far', 'chevron-right']} />
                              </div>
                            </td>
                            <td>
                              <div
                                className={classNames(
                                  'ids-result-value-final',
                                  getValue(table, key, after) ? 'success' : 'failed'
                                )}
                              >
                                <FontAwesomeIcon icon={getIcon(getValue(table, key, after))} />
                              </div>
                            </td>
                          </>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            );
          })
        ) : (
          <span className="ids-result-details-empty">No assessment data available</span>
        )}
      </div>
    </>
  );
};
