import React from 'react';

import classNames from 'classnames';
import { TIFFViewer } from 'react-tiff';

import { IdsResultDetailsModels } from './IdsResultDetailsModels';

export const IdsImagePreview = ({ result, title, className }) => {
  const imgInfo = result?.info;
  const hasTransparentBackGround = result?.results?.bg_color_type === 'Transparent';
  const isImageSupported =
    imgInfo &&
    IdsResultDetailsModels.VALID_EXTENSION.includes(imgInfo?.attachment_type?.toLowerCase());
  const isTiffImage = imgInfo && ['tif', 'tiff'].includes(imgInfo?.attachment_type?.toLowerCase());

  const getInvalidMessage = ({ imgInfo }) => {
    if (!isImageSupported && !isTiffImage) {
      return (
        <>
          The <strong>{imgInfo?.attachment_type}</strong> format is currently not supported for
          display.
        </>
      );
    }
    return 'This file is invalid';
  };
  const getImageDisplay = ({ imgInfo }) => {
    if (isImageSupported) {
      return (
        <img
          className={classNames('ids-image-preview-img', {
            transparent: hasTransparentBackGround,
          })}
          width="100%"
          // height="100%"
          src={imgInfo?.url}
          alt={imgInfo?.name}
        />
      );
    }

    if (isTiffImage) {
      return <TIFFViewer tiff={imgInfo?.url} />;
    }

    return <span>{getInvalidMessage({ imgInfo })}</span>;
  };

  return (
    <div className={classNames('ids-image-preview', className)}>
      <span className="ids-image-preview-title">{title}</span>
      <div className="ids-image-preview-wrapper">{imgInfo && getImageDisplay({ imgInfo })}</div>
    </div>
  );
};
