/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getIconWithPopover } from 'helpers/Utils';

/**
 * Presents metadata for each item within an assessment, including initial and potentially final
 * states. This component is part of the assessment results display, focusing on the metadata aspects
 * of the assessment items, which could include a variety of data points depending on the assessment's
 * context and purpose.
 *
 * Each item's metadata is rendered with a label and value(s), with the initial state always shown and
 * the final state conditionally rendered based on the `showFinal` prop. Tooltips provide additional
 * information for each metadata item, enhancing the user's understanding of the displayed data.
 *
 * @param {object} items An object mapping each item's identifier to its metadata, which includes the
 *                       label, initial and final value calculation functions, icon for display, and tooltip
 *                       content. This structure allows for flexible rendering of varied metadata items.
 * @param {object} row The data object representing the assessment results. It contains 'before' and 'after'
 *                     sections, from which initial and final values are derived for display.
 *
 * @returns Render of the `IdsResultDetailsMetadata` component, consisting of rows for each metadata
 *          item specified in the `items` prop. Each row displays the item's label, initial value, and,
 *          if applicable based on `showFinal`, the final value. Tooltips provide additional insights
 *          into each item's significance.
 */
export const IdsResultDetailsMetadata = ({ items, row }) => {
  const { before, after } = row;

  const showFinal = Object.keys(row?.after.results || {}).length > 0;

  const hasResultsToDisplay = Object.keys(items).some((key) => {
    return (
      items[key]?.get(before?.results || {}) || (showFinal && items[key]?.get(after?.results || {}))
    );
  });

  return (
    <>
      <div className="ids-result-details-header">Metadata</div>
      <div className="ids-result-details">
        {hasResultsToDisplay ? (
          <table className="ids-result-details-table" cellSpacing="0" cellPadding="0">
            <thead>
              <tr>
                <th className="ids-result-details-header" scope="col" />
                <th className="assessment-header-initial" scope="col">
                  Initial
                </th>
                {showFinal && (
                  <>
                    <th className="assessment-header-separator"> </th>
                    <th className="assessment-header-final" scope="col">
                      Final
                    </th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {Object.keys(items).map((key) => {
                return (
                  <tr key={`assessment-${key}`}>
                    <td>
                      <div className="ids-result-label">
                        <span className="ids-result-label-text">{items[key]?.label}</span>
                        <span className="ids-result-label-icon">
                          {getIconWithPopover({
                            placement: 'top',
                            iconProp: ['far', 'question-circle'],
                            content: items[key]?.tooltip,
                            title: items[key]?.label,
                          })}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="ids-result-value-initial">
                        {items[key]?.get(before?.results || {}) || 'N/A'}
                      </div>
                    </td>
                    {showFinal && (
                      <>
                        <td>
                          <div className="ids-result-value-separator">
                            <FontAwesomeIcon icon={['far', 'chevron-right']} />
                          </div>
                        </td>
                        <td>
                          <div className="ids-result-value-final">
                            {items[key]?.get(after?.results || {}) || 'N/A'}
                          </div>
                        </td>
                      </>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <span className="ids-result-details-empty">No metadata to display</span>
        )}
      </div>
    </>
  );
};
