import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getIconWithPopover } from '../../../../helpers/Utils';

export const IdsResultDetailsFailReasons = ({ items, row }) => {
  const { before, after } = row;
  const hasAfter = Object.keys(after?.results || {}).length > 0;
  const failReasons = (hasAfter ? after?.results : before?.results)?.fail_reasons || [];

  const reasonItems = Object.keys(items).filter((key) => failReasons.includes(key));
  return (
    <>
      <div className="ids-result-details-header">Fail Reasons</div>
      <div className="ids-result-details">
        {reasonItems?.length !== 0 ? (
          <table className="ids-result-details-table" cellSpacing="0" cellPadding="0">
            <tbody>
              {reasonItems.map((key) => {
                return (
                  <tr key={`assessment-${key}`}>
                    <td>
                      <div className="ids-result-details-fail-reason-icon">
                        <FontAwesomeIcon icon={['fas', 'exclamation-circle']} />
                      </div>
                    </td>
                    <td>
                      <div className="ids-result-label">
                        <span className="ids-result-value-text">{items[key].label}</span>
                        <span className="ids-result-label-icon">
                          {getIconWithPopover({
                            placement: 'top',
                            iconProp: ['far', 'question-circle'],
                            content: items[key].tooltip,
                            title: items[key].label,
                          })}
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <span className="ids-result-details-empty">No Fail Reasons</span>
        )}
      </div>
    </>
  );
};
