import React from 'react';

import { IdsResultDetailsAssessments } from './IdsResultDetailsAssessments';
import { IdsResultDetailsEnhancements } from './IdsResultDetailsEnhancements';
import { IdsResultDetailsFailReasons } from './IdsResultDetailsFailReasons';
import { IdsResultDetailsMetadata } from './IdsResultDetailsMetadata';
import { IdsResultDetailsModels } from './IdsResultDetailsModels';

/**
 * Displays the assessment results for a specific job row. This component aggregates and presents
 * different sections of assessment results, including metadata, assessments, and enhancements,
 * each with their own headers and content.
 *
 * The component dynamically renders headers and content for each assessment section based on
 * the data provided in `IdsAssessmentResultModels` and the `row` prop, adjusting for the presence
 * of final results where applicable. It structures the presentation into distinct sections for
 * clarity and ease of understanding.
 *
 * @param {object} row The data object representing a single row of assessment results. This includes
 *                     pre- and post-assessment data, along with any enhancements applied to the data.
 *                     The structure of `row` is expected to align with the `IdsAssessmentResultModels`
 *                     for correct rendering of labels, groups, and itemized results.
 *
 * @returns render.
 */
export const IdsResultDetailsExpandedRow = ({ row }) => {
  const hasResults =
    Object.keys(row?.after?.results || {}).length > 0 ||
    Object.keys(row?.before?.results || {}).length > 0;
  return (
    <>
      {hasResults ? (
        <div className="ids-result-details-container">
          <div
            className={`ids-result-details-item ids-result-group-${IdsResultDetailsModels.metadata.group}`}
          >
            <IdsResultDetailsMetadata row={row} items={IdsResultDetailsModels.metadata.items} />
          </div>

          <div className="divider" />

          <div
            className={`ids-result-details-item ids-result-group-${IdsResultDetailsModels.assessments.group}`}
          >
            <IdsResultDetailsAssessments
              row={row}
              items={IdsResultDetailsModels.assessments.items}
            />
          </div>
          <div className="divider" />

          <div
            className={`ids-result-details-item ids-result-group-${IdsResultDetailsModels.fail_reasons.group}`}
          >
            <IdsResultDetailsFailReasons
              row={row}
              items={IdsResultDetailsModels.fail_reasons.items}
            />
          </div>

          <div className="divider" />
          <div
            className={`ids-result-details-item ids-result-group-${IdsResultDetailsModels.enhancements.group}`}
          >
            <IdsResultDetailsEnhancements
              row={row}
              items={IdsResultDetailsModels.enhancements.items}
            />
          </div>
        </div>
      ) : (
        <div className="ids-result-details-empty">No results available</div>
      )}
    </>
  );
};
