import React, { useCallback, useEffect, useState } from 'react';

import './ImgBotEngineDetails.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import useBus from 'use-bus';

import * as ApiCalls from 'api/ApiCalls';
import { LoadingSpinner } from 'components/common/LoadingSpinner/LoadingSpinner';
import { BotConstants } from 'constants/BotConstants';
import { toast } from 'helpers/ToastUtils';
import { useIsMounted } from 'helpers/useIsMounted';

import { EventTypeConstants } from '../../constants/EventTypeConstants';
import { updateObject } from '../../helpers/Utils';
import { AssessmentSummary } from './_private/AssessmentSummary';
import { DetailsHeader } from './_private/DetailsHeader';
import { IdsModels } from './_private/IdsModels';
import { IdsUtils } from './_private/IdsUtils';
import { ImgBotEngineResultDetailsTable } from './_private/ImgBotEngineResultDetailsTable';
import { JobActions } from './_private/JobActions';

import './ImgBotEngineConfigDashboard.scss';
/**
 * IDS jobs details panel.
 *
 * @param {integer} id IDS job id - process_job
 *
 * @return render
 */
const ImgBotEngineDetails = ({ id }) => {
  const isMounted = useIsMounted();
  const history = useHistory();

  // TODO: endpoint should be consistent and bring the same structure as the log table.
  // IDS job(process_job)
  const [idsData, setIdsData] = useState(null);
  const [isLoadingIdsData, setIsLoadingIdsData] = useState(false);
  // DJ wrapper
  const [djData, setDjData] = useState(null);
  const [isLoadingDjData, setIsLoadingDjData] = useState(false);
  const [isIdsModalOpen, setIsIdsModalOpen] = useState(false);

  const doLoadIdsData = useCallback((_id, _isMounted) => {
    if (!_isMounted.current) {
      return;
    }

    if (!_id) {
      setIdsData(null);
      setIsLoadingIdsData(false);
      return;
    }

    setIsLoadingIdsData(true);

    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/bots/process-job/${_id}`,
      onSuccess: (res) => {
        if (_isMounted.current) {
          if (res?.data) {
            setIdsData(res.data);
          } else {
            setIdsData(null);
          }
        }
      },
      onEnd: () => {
        if (_isMounted.current) {
          setIsLoadingIdsData(false);
        }
      },
    });
  }, []);

  const refreshIdsStatus = useCallback((_idsData, timer, _idsModalOpen) => {
    if (!_idsData || _idsModalOpen) {
      return;
    }
    setIsLoadingIdsData(true);

    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/bots/process-job/${_idsData.id}/status`,
      onSuccess: (res) => {
        if (res?.data) {
          if (!_idsModalOpen) {
            const temp = updateObject(_idsData, res.data);
            setIdsData(temp);
          }
        }
      },
      onEnd: () => {
        setIsLoadingIdsData(false);
        clearTimeout(timer);
      },
    });
  }, []);

  useBus(EventTypeConstants.IDS_MODAL_STATE_CHANGED, (extra) => {
    if (extra?.state === 'open') {
      setIsIdsModalOpen(true);
    } else if (extra?.state === 'closed') {
      setIsIdsModalOpen(false);
    }
  });

  // Load IDS data on load
  useEffect(() => {
    doLoadIdsData(id, isMounted);
  }, [doLoadIdsData, id, isMounted]);

  const doLoadDjData = useCallback((_id, _isMounted) => {
    if (!_isMounted.current) {
      return;
    }

    if (!_id) {
      setDjData(null);
      setIsLoadingDjData(false);
      return;
    }

    setIsLoadingDjData(true);

    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/data-requests/${_id}`,
      onSuccess: (res) => {
        if (_isMounted.current) {
          if (res?.data) {
            setDjData(res.data);
          } else {
            setDjData(null);
          }
        }
      },
      onEnd: () => {
        if (_isMounted.current) {
          setIsLoadingDjData(false);
        }
      },
    });
  }, []);

  useEffect(() => {
    let timer;
    if (idsData) {
      const state = IdsUtils.getStatus(idsData);
      if (
        [
          BotConstants.BOT_STATUSES.COMPLETED,
          BotConstants.BOT_STATUSES.CANCELED,
          BotConstants.BOT_STATUSES.FAILED,
        ].includes(state.status) ||
        isIdsModalOpen
      ) {
        return;
      }
      timer = setTimeout(() => {
        refreshIdsStatus(idsData, timer, isIdsModalOpen);
      }, 30 * 1000);
    }
    return () => clearTimeout(timer);
  }, [refreshIdsStatus, idsData, isMounted, isIdsModalOpen]);

  // Load DJ wrapper when IDS job is loaded
  useEffect(() => {
    doLoadDjData(idsData?.data_request, isMounted);
  }, [doLoadDjData, idsData?.data_request, isMounted]);

  const [isLoadingAction, setIsLoadingAction] = useState(false);

  // TODO: Extract BE calls to util helper
  const onRunAssessment = ({
    jobId,
    file,
    selectedUrlHeader,
    selectedSkuHeader,
    customConfig,
    onSuccess,
    assessmentConfigId = undefined,
    enhancementConfigId = undefined,
  }) => {
    if (isLoadingAction) {
      return;
    }
    setIsLoadingAction(true);

    const reqData = {
      file_name: file,
    };

    if (selectedUrlHeader?.length) {
      reqData.header = selectedUrlHeader;
    }

    if (selectedSkuHeader?.length) {
      reqData.product_code_header = selectedSkuHeader;
    }

    if (customConfig) {
      reqData.config_data = customConfig;
    }

    if (assessmentConfigId !== undefined) {
      reqData.assessment_config_id = assessmentConfigId;
    }

    if (enhancementConfigId !== undefined) {
      reqData.enhancement_config_id = enhancementConfigId;
    }

    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.PUT,
      urlPath: `/bots/process-job/${jobId}`,
      data: reqData,
      onSuccess: (res) => {
        if (res?.data) {
          toast.success('Assessment started...');
          doLoadIdsData(id, isMounted);
          if (typeof onSuccess === 'function') {
            onSuccess(res);
          }
        }
      },
      onEnd: () => setIsLoadingAction(false),
    });
  };

  const onRunEnhancement = ({
    jobId,
    onSuccess,
    customConfig,
    enhancementConfigId = undefined,
  }) => {
    if (isLoadingAction) {
      return;
    }
    setIsLoadingAction(true);

    const reqData = {
      ...customConfig,
    };

    if (enhancementConfigId !== undefined) {
      reqData.enhancement_config_id = enhancementConfigId;
    }
    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.PUT,
      urlPath: `/bots/process-job/${jobId}/enhancement`,
      data: reqData,
      onSuccess: (res) => {
        if (res?.data) {
          toast.success('Enhancement started...');
          doLoadIdsData(id, isMounted);
          if (typeof onSuccess === 'function') {
            onSuccess(res);
          }
        }
      },
      onEnd: () => setIsLoadingAction(false),
    });
  };

  const onCancelJob = ({ jobId }) => {
    if (isLoadingAction) {
      return;
    }
    setIsLoadingAction(true);

    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.POST,
      urlPath: `/bots/process-job/cancel/${jobId}`,
      onSuccess: (res) => {
        if (res?.data) {
          toast.warn('IDS job was canceled');

          history.push('/image-databot-suite');
        }
      },
      onEnd: () => setIsLoadingAction(false),
    });
  };

  const goBack = () => {
    if (history.location.state?.from?.pathname?.includes('/image-databot-suite')) {
      history.goBack();
    } else {
      history.push(`/image-databot-suite`);
    }
  };

  const onQualityRequest = ({ jobId }) => {
    if (isLoadingAction) {
      return;
    }
    setIsLoadingAction(true);

    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.POST,
      urlPath: `/bots/process-job/${jobId}/request/quality_check`,
      onSuccess: (res) => {
        if (res?.data) {
          toast.success(`Request submitted for job with ID: ${jobId}.`);
          doLoadIdsData(id, isMounted);
        }
      },
      onEnd: () => setIsLoadingAction(false),
    });
  };

  return (
    <>
      <div className="top-actions">
        <button type="button" onClick={goBack} className="btn btn-secondary btn-sm">
          <span className="icon" style={{ marginRight: '0.5em' }}>
            <FontAwesomeIcon icon={['far', 'long-arrow-left']} />
          </span>
          <span className="label">IDS Dashboard</span>
        </button>
      </div>
      <div className="title">
        <h3>Image Databot Suite - Job Details</h3>

        {!!djData?.id && !!idsData?.id && (
          <div className="actions">
            <JobActions
              jobRow={{
                ...djData,
                process_job: idsData,
                onRunAssessment,
                onRunEnhancement,
                onCancelJob,
                onQualityRequest,
                isLoading: isLoadingIdsData || isLoadingDjData || isLoadingAction,
              }}
              isLargeBtn
            />
          </div>
        )}
      </div>
      <DetailsHeader
        idsData={idsData}
        djData={djData}
        isLoadingIdsData={isLoadingIdsData}
        isLoadingDjData={isLoadingDjData}
      />
      <div className="assessment-titles-wrap">
        <div className="assessment-title assessment-title-initial">
          <h2>
            Initial Assessment
            {!!isLoadingIdsData && <LoadingSpinner fast />}
          </h2>
        </div>
        <div className="assessment-title assessment-title-initial">
          <h2>
            Final Assessment
            {!!isLoadingIdsData && <LoadingSpinner fast />}
          </h2>
        </div>
      </div>
      <div className="assessments-summary-wrap">
        <div className="assessment-summary assessment-summary-initial">
          <AssessmentSummary
            statsData={idsData?.statistics?.initial}
            additionalStatItems={
              [
                // IdsModels.ASSESSMENT_MODEL.failed_items.key,
                // IdsModels.ASSESSMENT_MODEL.has_multiple_products.key,
                // IdsModels.ASSESSMENT_MODEL.has_watermark.key,
                // IdsModels.ASSESSMENT_MODEL.invalid_items.key,
                // IdsModels.ASSESSMENT_MODEL.has_person.key,
                // IdsModels.ASSESSMENT_MODEL.is_placeholder.key,
                // IdsModels.ASSESSMENT_MODEL.is_schema.key,
              ]
            }
            isLoadingIdsData={isLoadingIdsData}
            title="Initial Assessment"
          />
        </div>
        <div className="assessment-summary assessment-summary-final">
          <AssessmentSummary
            statsData={idsData?.statistics?.final}
            isLoadingIdsData={isLoadingIdsData}
            title="Final Assessment"
            additionalStatItems={[IdsModels.ASSESSMENT_MODEL.is_transformed.key]}
          />
        </div>
      </div>

      <div className="assessment-results-wrap">
        <div className="title">
          <h4>Results</h4>
        </div>
        <ImgBotEngineResultDetailsTable id={id} showFilters />
      </div>
    </>
  );
};

export { ImgBotEngineDetails };
