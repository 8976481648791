import React, { useState, useEffect, useCallback, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Alert, Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import * as ApiCalls from 'api/ApiCalls';
import { useDebounce } from 'helpers/useDebounce';

import './ImgBotEngineResultDetailsTable.scss';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import { IdsResultDetailsExpandedRow } from './_assessments/IdsResultDetailsExpandedRow';
import { IdsResultDetailsTableDef } from './_assessments/IdsResultDetailsTableDef';

/**
 * Renders a dynamic and interactive table displaying assessment results for a specific ImgBot job.
 * Supports pagination, dynamic data loading based on search queries, and expandable rows for detailed information.
 *
 * @param {string} id Unique identifier for the job whose assessment results are to be displayed. Utilized in API calls to fetch relevant data.
 * @param {boolean} showFilters Determines if the search filter input should be visible to the user, allowing for filtering of the results by file names.
 * @param {number} itemsLimit Specifies the maximum number of items to display per page within the table. Passed to the API for managing pagination.
 * @returns render.
 */

export const ImgBotEngineResultDetailsTable = ({ id, showFilters, itemsLimit }) => {
  const DEFAULT_PAGE_SIZE = 20;
  const [jobId] = useState(id);
  const [query, setQuery] = useState('');
  const [logData, setLogData] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);

  const [tableState, setTableState] = useState({
    page: 1,
    total: 0,
    offset: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    itemsLimit,
  });

  const searchFieldRef = useRef();
  const searchQuery = useDebounce(query, 500);

  const loadAssessmentResults = useCallback(() => {
    const searchInFocus = document.activeElement === searchFieldRef.current;
    const queryString = query ? `&original_name=${query}` : '';
    const paginationQuery = `?limit=${tableState.pageSize}&offset=${
      tableState.offset
    }${queryString.replace(/\s/g, '')}`;

    setIsLoading(true);

    ApiCalls.doCall({
      method: ApiCalls.HTTP_METHODS.GET,
      urlPath: `/bots/process-job/${jobId}/results${paginationQuery}`,
      onSuccess: (res) => {
        if (res?.data) {
          setLogData(res?.data?.results);
          setTableState((prevTableState) => ({
            ...prevTableState,
            total: res?.data?.total || 0,
            pageSize: res?.data?.limit || DEFAULT_PAGE_SIZE,
          }));
        }
      },
      onEnd: () => {
        setIsLoading(false);
        if (searchInFocus) {
          searchFieldRef.current?.focus();
        }
      },
    });
  }, [jobId, query, tableState.offset, tableState.pageSize]);

  useEffect(() => {
    if (searchQuery || query.length <= 0) {
      loadAssessmentResults();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, tableState.page, tableState.pageSize]);

  const handleTableChange = (type, { page, sizePerPage }) => {
    const newOffset = (page - 1) * sizePerPage;
    setTableState((prevTableState) => ({
      ...prevTableState,
      page,
      pageSize: sizePerPage,
      offset: newOffset,
    }));
  };

  useEffect(() => {
    setTableState((prevTableState) => ({
      ...prevTableState,
      page: 1,
      offset: 0,
    }));
  }, [searchQuery]);

  useEffect(() => {
    return () => {
      setExpandedRows([]);
    };
  }, [tableState]);

  const expandRow = {
    renderer: (row) => (
      <div>
        <IdsResultDetailsExpandedRow row={row} />
      </div>
    ),
    expanded: expandedRows,
    onlyOneExpanding: true,
    expandColumnPosition: 'right',
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: () => {
      return <></>;
    },
    expandColumnRenderer: ({ expanded }) => {
      return (
        <>
          <Button type="button" size="sm" className="btn">
            <FontAwesomeIcon icon={['fa', expanded ? 'caret-up' : 'caret-down']} />
          </Button>
        </>
      );
    },
  };

  return (
    <div>
      {showFilters && (
        <div className="ids-result-details-filter">
          <Form.Control
            ref={searchFieldRef}
            value={query}
            placeholder="Search for a file by name"
            onChange={(event) => setQuery(event.target.value)}
            aria-label="Search for a file by name"
            disabled={isLoading}
          />
        </div>
      )}

      {logData?.length === 0 ? (
        <div className="empty-notice">
          <Alert variant="primary">No assessment results found.</Alert>
        </div>
      ) : (
        <div className={isLoading ? 'ids-assessment-result-table-disabled' : ''}>
          <BootstrapTable
            classes="ids-assessment-result-table"
            remote
            bordered={false}
            loading={isLoading}
            bootstrap4
            keyField="id"
            data={logData?.length ? logData : []}
            columns={IdsResultDetailsTableDef.columns}
            expandRow={expandRow}
            defaultSorted={[{ dataField: 'id', order: 'desc' }]}
            onTableChange={handleTableChange}
            pagination={paginationFactory({
              totalSize: tableState.total,
              onPageChange: (page) => setTableState((prev) => ({ ...prev, page })),
              sizePerPageList: [],
              sizePerPage: tableState.pageSize,
              hidePageListOnlyOnePage: true,
              onSizePerPageChange: (sizePerPage) =>
                setTableState((prev) => ({ ...prev, pageSize: sizePerPage, page: 1 })),
            })}
          />
        </div>
      )}
    </div>
  );
};
