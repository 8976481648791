import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getIconWithPopover } from 'helpers/Utils';

/**
 * Renders a list of enhancement results for a specific assessment, indicating whether each enhancement
 * was applied or not. Enhancements are represented by icons alongside their labels, with additional
 * tooltip information provided on hover for each item.
 *
 * This component visually represents the enhancements applied to an assessment post-processing,
 * using icons to indicate success (applied) or pending actions (not applied). It leverages the FontAwesome
 * library for icons and a custom `getIconWithPopover` method for tooltips, enhancing user interaction by
 * providing more context on hover.
 *
 * @param {object} items An object mapping enhancement identifiers to their respective metadata, including
 *                       labels, icons, and tooltip content. This structure supports dynamic rendering based
 *                       on the enhancements applied to the assessment.
 * @param {object} row The data object for the current row in the assessment results table. It includes
 *                     the `after` property, which contains the results of the assessment post-enhancements.
 *                     The `after.results.applied_enhancements` array lists the enhancements that were applied.
 *
 * @returns Render.
 */
export const IdsResultDetailsEnhancements = ({ items, row }) => {
  const { after, before } = row;

  const hasAppliedAfter = Object.keys(after.results || {}).length > 0;
  const iconLabel = hasAppliedAfter ? 'final' : 'initial';
  const iconProp = hasAppliedAfter ? ['fas', 'check'] : ['fa', 'chevron-right'];

  const enhancements = hasAppliedAfter
    ? after?.results?.applied_enhancements
    : before?.results?.applied_enhancements;

  const enhancementsItems = Object.keys(items).filter((key) =>
    (enhancements || []).includes(items[key]?.label)
  );

  const enhancementAction = hasAppliedAfter ? 'Applied' : 'Required';

  return (
    <>
      <div className="ids-result-details-header">{`${enhancementAction} Enhancements`}</div>
      <div className="ids-result-details">
        {enhancementsItems?.length !== 0 ? (
          <table className="ids-result-details-table">
            <tbody>
              {enhancementsItems.map((key) => {
                return (
                  <tr key={`applied-enhancements-${key}`}>
                    <td>
                      <span className={`ids-result-details-enhancement-icon-${iconLabel}`}>
                        <FontAwesomeIcon icon={iconProp} />
                      </span>
                    </td>
                    <td>
                      <div className="ids-result-label" key={`applied-enhancements-${key}`}>
                        <span className="ids-result-value-text">{items[key]?.label}</span>
                        <span className="ids-result-label-icon">
                          {getIconWithPopover({
                            placement: 'top',
                            iconProp: ['far', 'question-circle'],
                            content: items[key]?.tooltip,
                            title: items[key]?.label,
                          })}
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <span className="ids-result-details-empty">{`No Enhancements ${enhancementAction}`}</span>
        )}
      </div>
    </>
  );
};
