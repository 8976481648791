import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import { dispatch } from 'use-bus';

import { ModalPanel } from 'components/common/ModalPanel/ModalPanelWButton';

import { EventTypeConstants } from '../../../../constants/EventTypeConstants';
import { IdsImagePreview } from './IdsImagePreview';
import { IdsResultDetailsEnhancements } from './IdsResultDetailsEnhancements';
import { IdsResultDetailsMetadata } from './IdsResultDetailsMetadata';
import { IdsResultDetailsModels } from './IdsResultDetailsModels';

import './IdsResultDetailsImagePreviewModal.scss';

export const IdsResultDetailsImagePreviewModal = ({ row }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isIdsSlideOpen, setIsIdsSlideOpen] = useState(true);

  const { before, after } = row;
  const title = row.name;
  const displayWidth = (row?.after.results?.width || 0) + (row?.before.results?.width || 0);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const isSmallDisplay = displayWidth / viewportWidth < 0.5 && viewportWidth > 1200;

  useEffect(() => {
    const handleResize = () => setViewportWidth(window.innerWidth);

    if (isModalOpen) {
      window.addEventListener('resize', handleResize);
    }
    return () => window.removeEventListener('resize', handleResize);
  }, [isModalOpen]);

  const onModalStateChanged = (isOpen) => {
    setIsModalOpen(isOpen);
    dispatch({
      type: EventTypeConstants.IDS_MODAL_STATE_CHANGED,
      state: isOpen ? 'open' : 'closed',
    });
  };

  const hasFinalResults = Object.keys(row?.after?.results || {}).length > 0;
  const hasInitialResults = Object.keys(row?.before?.results || {}).length > 0;
  const sidebarIcon = isIdsSlideOpen ? ['fas', 'chevron-right'] : ['fas', 'info'];
  const hasResults = hasFinalResults || hasInitialResults;

  return (
    <ModalPanel
      contentClassName="ids-result-modal-content"
      className={classNames('ids-result-modal-panel', { 'small-display': isSmallDisplay })}
      backdrop="static"
      header={<div className="ids-result-model-header">{title}</div>}
      body={() => (
        <div className="ids-result-model-container">
          <div className="ids-result-image-content">
            <IdsImagePreview result={before} title="Before" className="image-before" />
            {hasFinalResults && (
              <>
                <span className="ids-separator" />
                <IdsImagePreview result={after} title="After" className="image-after" />
              </>
            )}
          </div>

          {hasResults && (
            <>
              <div className="ids-result-modal-side-panel-separator">
                <span className="ids-separator" />
                <Button
                  type="button"
                  size="sm"
                  className="btn btn-icon ids-result-modal-separator-toggle"
                  onClick={() => setIsIdsSlideOpen(!isIdsSlideOpen)}
                >
                  <FontAwesomeIcon icon={sidebarIcon} />
                </Button>
              </div>

              <div
                className={classNames('ids-results-sidebar-panel ids-result-details-container', {
                  'sidebar-closed': !isIdsSlideOpen,
                  'ids-final-results': hasFinalResults,
                })}
              >
                <div className="ids-result-details-item">
                  <IdsResultDetailsMetadata
                    row={row}
                    items={IdsResultDetailsModels.metadata.items}
                  />
                </div>
                <div className="ids-result-details-item  items-applied-enhancements">
                  <IdsResultDetailsEnhancements
                    items={IdsResultDetailsModels.enhancements.items}
                    row={row}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      )}
      centered
      onOpen={() => onModalStateChanged(true)}
      onClose={() => onModalStateChanged(false)}
    >
      {({ setIsVisible }) => (
        <>
          <button
            type="button"
            className="ids-model-btn-file-search file-search link"
            onClick={() => setIsVisible(true)}
            disabled={!hasFinalResults && Object.keys(before?.info || {}).length === 0}
            title={hasResults ? 'Assessments' : 'No results available'}
          >
            <FontAwesomeIcon icon={['far', 'file-search']} />
          </button>
        </>
      )}
    </ModalPanel>
  );
};
