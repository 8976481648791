import { React } from 'react';

const VALID_EXTENSION = ['png', 'jpeg', 'jpg', 'webp', 'bmp', 'gif', 'svg', 'ico', 'apng', 'avif'];

const ASSESSMENT_RESULTS_GROUPS = {
  metadata: { label: 'Metadata', name: 'metadata' },
  enhancements: { label: 'Applied Enhancements', name: 'applied-enhancements' },
  assessments: { label: 'Assessments', name: 'assessments' },
  fail_reasons: { label: 'Fail Reasons', name: 'fail-reasons' },
};

const ASSESSMENT_RESULTS_METADATA = {
  dimension: {
    label: 'Dimension',
    tooltip: (
      <>
        The dimensions (<code>width</code> and <code>height</code>) of an image in digital format.
        Measured in pixels (px), and displayed as <code>width x height</code>.
      </>
    ),
    get: (item) => (item?.width && item?.height ? `${item?.width}x${item?.height}` : undefined),
  },
  resolution: {
    label: 'Resolution',
    tooltip: (
      <>
        Measures the density of dots for printing. Higher values ensure detailed images. Assessed by
        verifying the <code>XResolution</code> and <code>YResolution</code> image metadata fields.
        Reported as <code>XResolution x YResolution</code>.
      </>
    ),
    get: (item) =>
      item?.x_resolution && item?.y_resolution
        ? `${item?.x_resolution}x${item?.y_resolution}`
        : undefined,
  },
  format: {
    label: 'Format',
    tooltip: 'Refers to the method and structure used to store and encode digital images.',
    get: (item) => (item?.format ? item.format : undefined),
  },
  color_mode: {
    label: 'Color Mode',
    tooltip: (
      <>
        Defines the spectrum and method of color representation in an image. Common modes include
        RGB (Red, Green, Blue) for screen display, CMYK (Cyan, Magenta, Yellow, Key/Black) for
        printing, and Grayscale for black-and-white images. Each mode directly influences the color
        accuracy and depth of an image.
      </>
    ),
    get: (item) => (item?.color_mode ? item?.color_mode : undefined),
  },
};

const ASSESSMENT_RESULTS_ASSESSMENTS = {
  table_1: {
    is_min_image_size: {
      label: 'Min. Image Size',
      tooltip: (
        <>
          Assesses the images <code>width</code> and <code>height</code> dimensions (in pixels) and
          ensures they are above the acceptance criteria.
          <br />
          Acceptance thresholds are defined using the <code>
            Min. Longest Dimension(px)
          </code> and <code>Min. Shortest Dimension</code> assessment configuration options.
        </>
      ),
      get: (item) => item?.is_min_image_size,
    },
    is_min_image_resolution: {
      label: 'Min. Image Resolution',
      tooltip: (
        <>
          Assesses the images <code>XResolution</code> and <code>YResolution</code> metadata fields,
          and ensures it meets the acceptance criteria.
          <br />
          The acceptance threshold is defined using the <code>Min. Image Resolution(DPI)</code>{' '}
          assessment configuration option.
        </>
      ),
      get: (item) => item?.is_min_image_resolution,
    },
    is_blurry: {
      label: 'Is Blurry',
      tooltip: (
        <>
          Assesses the sharpness of the products in the image and ensures it meets the acceptance
          criteria.
          <br />
          The threshold to consider an image sharp is defined using the Blur Acceptance(%)
          assessment configuration option.
        </>
      ),
      get: (item) => item?.is_blurry,
    },
    is_centered: {
      label: 'Centered',
      tooltip: (
        <>
          Assesses the products alignment relative to the image. The IDS calculates the offset
          between the center of the image and the center of the product and ensures it is within an
          acceptance range.
          <br />
          The offset threshold for considering a product centered in the image is defined using the{' '}
          <code>Product Alignment Threshold(%)</code> assessment configuration option.
        </>
      ),
      get: (item) => item?.is_centered,
    },
    is_perfectly_cropped: {
      label: 'Correctly Cropped',
      tooltip: (
        <>
          Assesses the amount of padding that exists around the product and the edges of the image.
          <br />
          The padding acceptance threshold is defined using the <code>
            Padding Acceptance(%)
          </code>{' '}
          assessment configuration option.
        </>
      ),
      get: (item) => item?.is_perfectly_cropped,
    },
    is_duplicated: {
      label: 'Is Duplicated',
      tooltip: <>Images that have been detected as duplicates of other images.</>,
      get: (item) => item?.is_duplicated,
    },
  },
  table_2: {
    has_multiple_products: {
      label: 'Multiple Products',
      tooltip: (
        <>
          Assesses the number of items that are depicted in the image.
          <br />
          By default, images depicting more than one item will fail this assessment. To allow images
          having multiple items to pass this assessment, you can check the{' '}
          <code>Allow Multiple Products</code> assessment configuration flag.
        </>
      ),
      get: (item) => item?.has_multiple_products,
    },
    has_person: {
      label: 'Person Detected',
      tooltip: <>Images where a person or a body part was detected.</>,
      get: (item) => item?.has_person,
    },
    has_watermark: {
      label: 'Watermark Detected',
      tooltip: (
        <>
          Assesses if a watermark is present in the image.
          <br />
          Watermarked images will fail this assessment. The strictness with which the algorithm
          considers an image being watermarked or not is controlled using the{' '}
          <code>Watermark Detection(%)</code> assessment configuration option.
        </>
      ),
      get: (item) => item?.has_watermark,
    },
    is_placeholder: {
      label: 'Blank/Placeholder',
      tooltip: (
        <>
          Identifies placeholder, or completely blank images, and flags them accordingly.
          <br />
          The strictness with which the algorithm considers an image blank/placeholder is controlled
          using the <code>Placeholder Detection(%)</code> assessment configuration option.
        </>
      ),
      get: (item) => item?.is_placeholder,
    },
    is_schema: {
      label: 'Diagram/Line Drawing',
      tooltip: (
        <>
          Identifies if the image is a diagram, and flags it accordingly. Diagram images are handled
          differently by the IDS.
          <br />
          The strictness with which the algorithm considers an image a diagram is controlled using
          the <code>Diagram Detection(%)</code> assessment configuration option.
        </>
      ),
      get: (item) => item?.is_schema,
    },
    is_bg_cluttered: {
      label: 'Cluttered Background',
      tooltip: (
        <>
          Assesses the background of the image, and flags accordingly the images with non-uniform,
          cluttered backgrounds.
        </>
      ),
      get: (item) => item?.is_bg_cluttered,
    },
  },
};

const APPLIED_ENHANCEMENTS = {
  cropping: {
    label: 'Cropping',
    tooltip: (
      <>
        Automatically crops the image such the product is tightly fit.
        <br />
        Padding around the product can be added using the <code>Crop Padding(%)</code> enhancement
        configuration option.
      </>
    ),
  },
  enlargement: {
    label: 'Enlargement',
    tooltip: <>Increases the pixel dimensions of the image.</>,
  },
  background_removal: {
    label: 'Background Removal',
    tooltip: 'Removes the background pixels around the product.',
  },
  dpi_correction: {
    label: 'DPI Correction',
    tooltip: (
      <>
        Sets the resolution-related metadata fields (<code>XResolution</code> and{' '}
        <code>YResolution</code>) to the desired value.
        <br />
        The target DPI can be configured using the <code>Target Resolution(DPI)</code> enhancement
        configuration option.
      </>
    ),
  },
  format_conversion: {
    label: 'Format Conversion',
    tooltip: (
      <>
        Converts the images in one of the supported formats. The desired format is selected using
        the <code>Output Format</code> enhancement configuration option.
      </>
    ),
  },
  sharpening: {
    label: 'Sharpening',
    tooltip: 'Enhances the clarity and reduces the blurriness of the image.',
  },
};

const FAIL_REASONS = {
  'Image Size': {
    label: 'Image Size',
    tooltip: (
      <>
        The image dimensions are below the minimum required size. The minimum size is defined by the
        Min. Longest Dimension(px) and Min. Shortest Dimension assessment configuration options.
      </>
    ),
  },
  'Image Resolution': {
    label: 'Image Resolution',
    tooltip: (
      <>
        The image resolution is below the minimum required resolution. The minimum resolution is
        defined by the Min. Image Resolution(DPI) assessment configuration option.
      </>
    ),
  },
  Blurry: {
    label: 'Blurry',
    tooltip: (
      <>
        The image is blurry. The sharpness threshold is defined by the Blur Acceptance(%) assessment
        configuration option.
      </>
    ),
  },
  Centered: {
    label: 'Centered',
    tooltip: (
      <>
        The product is not centered in the image. The offset between the center of the image and the
        center of the product is outside the acceptance range. The acceptance range is defined by
        the Product Alignment Threshold(%) assessment configuration option.
      </>
    ),
  },
  'Incorrectly Cropped': {
    label: 'Incorrectly Cropped',
    tooltip: (
      <>
        The image has incorrect padding around the product. The padding acceptance threshold is
        defined by the Padding Acceptance(%) assessment configuration option.
      </>
    ),
  },
  Placeholder: {
    label: 'Placeholder',
    tooltip: (
      <>
        The image is a placeholder or completely blank. The strictness with which the algorithm
        considers an image blank/placeholder is controlled using the Placeholder Detection(%)
        assessment configuration option.
      </>
    ),
  },
  'Multiple Products': {
    label: 'Multiple Products',
    tooltip: (
      <>
        The image contains more than one product. By default, images with multiple products fail
        this assessment. To allow images with multiple products, you can check the Allow Multiple
        Products assessment configuration flag.
      </>
    ),
  },
  Watermark: {
    label: 'Watermark',
    tooltip: (
      <>
        The image contains a watermark. Watermarked images fail this assessment. The strictness with
        which the algorithm considers an image watermarked or not is controlled using the Watermark
        Detection(%) assessment configuration option.
      </>
    ),
  },
  'Not a white or transparent Background': {
    label: 'Not a white or transparent Background',
    tooltip: (
      <>
        The image background is not white or transparent. The background should be uniform and
        clutter-free.
      </>
    ),
  },
  'Image Not Found': {
    label: 'Image Not Found',
    tooltip: 'The image was not found.',
  },
  'Failed Download': {
    label: 'Failed Download',
    tooltip: 'The image download failed due to an unknown error.',
  },
  'Invalid Image': {
    label: 'Invalid Image',
    tooltip: 'The image is invalid or corrupted.',
  },
  'Invalid File Format': {
    label: 'Unsupported File Format',
    tooltip: 'The image file format is not supported.',
  },
  'Missing Url': {
    label: 'Missing Url',
    tooltip: 'The image URL is missing.',
  },
  'Image Too Small': {
    label: 'Image Too Small',
    tooltip: 'The image is too small.',
  },
  'Processing Failed': {
    label: 'Processing Failed',
    tooltip: 'The image processing failed due to an unknown error.',
  },
  'Not Provided': {
    label: 'Not Provided',
    tooltip: 'The image was not provided.',
  },
};

export const IdsResultDetailsModels = {
  VALID_EXTENSION,
  metadata: {
    label: ASSESSMENT_RESULTS_GROUPS.metadata.label,
    group: ASSESSMENT_RESULTS_GROUPS.metadata.name,
    items: ASSESSMENT_RESULTS_METADATA,
  },
  assessments: {
    label: ASSESSMENT_RESULTS_GROUPS.assessments.label,
    group: ASSESSMENT_RESULTS_GROUPS.assessments.name,
    items: ASSESSMENT_RESULTS_ASSESSMENTS,
  },
  enhancements: {
    label: ASSESSMENT_RESULTS_GROUPS.enhancements.label,
    group: ASSESSMENT_RESULTS_GROUPS.enhancements.name,
    items: APPLIED_ENHANCEMENTS,
  },
  fail_reasons: {
    label: ASSESSMENT_RESULTS_GROUPS.fail_reasons.label,
    group: ASSESSMENT_RESULTS_GROUPS.fail_reasons.name,
    items: FAIL_REASONS,
  },
};
