import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as Utils from 'helpers/Utils';
import { getDefaultTableColumnDef, wrapWithTooltip } from 'helpers/Utils';

import { IdsResultDetailsImagePreviewModal } from './IdsResultDetailsImagePreviewModal';

export const IdsResultDetailsTableDef = {
  columns: [
    {
      ...getDefaultTableColumnDef('file', 'File Name'),
      sort: false,
      formatter: (_cell, row) => {
        const [fName, fExt] = Utils.splitExt(row?.name);
        return (
          <>
            {wrapWithTooltip({
              content: row?.name,
              target: (
                <div className="file-block-name">
                  <span className="fname">{fName}</span>
                  <span className="fext">.{fExt}</span>
                </div>
              ),
            })}
          </>
        );
      },
    },

    {
      ...getDefaultTableColumnDef('market_ready', 'Market Ready'),
      sort: false,
      formatter: (_cell, row) => {
        const failReasons = (row?.after?.results || row?.before?.results)?.fail_reasons || [];
        const output = failReasons.length ? (
          <div className="ids-result-details-market-ready-icon-fail">
            <FontAwesomeIcon icon={['fas', 'times-circle']} />
          </div>
        ) : (
          <div className="ids-result-details-market-ready-icon-pass">
            <FontAwesomeIcon icon={['fas', 'check']} />
          </div>
        );
        return <div className="market_ready"> {output} </div>;
      },
    },

    {
      ...getDefaultTableColumnDef('before_after', 'Before/After'),
      sort: false,
      formatter: (_cell, row) => {
        return <IdsResultDetailsImagePreviewModal row={row} />;
      },
    },
  ],
};
